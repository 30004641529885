.swiper-slide {
   overflow: hidden;
   height: auto;
}
.swiper-button-prev,
.swiper-button-next {
  color: #FF9B9C;
}
.swiper-pagination-bullet-active {
  background: #FF9B9C;
}
#carousel {
  img {
    object-fit: cover;
   }
   .card {
    border-radius: 0;
    border: 0;
    height: 100%;
  }
  .card-body {
    height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .logo {
    top: 0;
    left: 0;
    max-width: 9rem;
    max-height: 5rem;
  }
  .button-link {
    width: 200px;
  }
  a:link, a:visited {
    color: #222;
  }
  a:hover, a:active {
    color: #eee;
  }
}